export const SAVE_MOVEMENT = 'SAVE_MOVEMENT';
export const SAVE_MOVEMENT_STARTED = 'SAVE_MOVEMENT_STARTED';
export const SAVE_MOVEMENT_FAILURE = 'SAVE_MOVEMENT_FAILURE';

export const EDIT_MOVEMENT = 'EDIT_MOVEMENT';
export const EDIT_MOVEMENT_STARTED = 'EDIT_MOVEMENT_STARTED';
export const EDIT_MOVEMENT_FAILURE = 'EDIT_MOVEMENT_FAILURE';

export const SEND_DOCUMENT = 'SEND_DOCUMENT';

export const GET_MOVEMENTS_COUNT = 'GET_MOVEMENTS_COUNT';
export const GET_MOVEMENTS_COUNT_STARTED = 'GET_MOVEMENTS_COUNT_STARTED';
export const GET_MOVEMENTS_COUNT_FAILURE = 'GET_MOVEMENTS_COUNT_FAILURE';

export const GET_OPERATIONS = 'GET_OPERATIONS';
export const GET_OPERATIONS_STARTED = 'GET_OPERATIONS_STARTED';
export const GET_OPERATIONS_FAILURE = 'GET_OPERATIONS_FAILURE';

export const GET_MOVEMENTS_TO_ADJUST = 'GET_MOVEMENTS_TO_ADJUST';
export const GET_MOVEMENTS_SUMMARY = 'GET_MOVEMENTS_SUMMARY';
