import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';

import { _portfolioWalletMock } from 'src/_mock/_wallets';
import { LOGOS_URL, S3_URL } from 'src/config';
import { Notification } from 'src/redux/slices/NotificationsSlice/notificationsSlice.d';
import { addPortfolio, updateConnection } from 'src/redux/slices/PortfolioSlice/portfolioSlice';
import { ConnectionType } from 'src/redux/slices/PortfolioSlice/portfolioSlice.d';
import { RootState } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getConnectionsAction } from 'src/services/connections';
import { ASSETS_COLORS } from 'src/theme/palette';
import { replaceAll } from './formatText';
import { log } from './logger';

export const transformNotifications = (notifications: Notification[], state: RootState) =>
  notifications.reverse().map((not) => {
    let { message } = not;
    let secondaryId = '';
    let topic = '';
    switch (not.code) {
      case 'PAYMENT_FAILED':
        topic = 'Subscripción';
        message = 'Se ha producido un error al realizar el pago de tu subscripción.';
        break;
      case 'MAPPED_SECURITIES':
        topic = 'Nuevos activos importados';
        message =
          'Hemos añadido activos que no pudimos encontrar anteriormente en tus carteras. Por favor comprueba tus carteras y reimporta para ver dichos activos en tu cartera.';
        break;
      case 'CONNECTION_SUCCESS':
        const object = JSON.parse(not.message);
        topic = `Conexión con ${object?.connectedEntityName} realizada correctamente`;
        message = `Se ha importado ${object?.newPortfolios} carteras de inversión y ${object?.newCashAccounts} cuentas corrientes.`;
        secondaryId = object.connection;
        break;
      case 'CONNECTION_REIMPORT_SUCCESS':
        try {
          const reimportObject = JSON.parse(not.message);
          topic = `Conexión con ${reimportObject?.connectedEntityName} reimportada correctamente`;
          message = `Se ha reimportado correctamente tu conexión con ${reimportObject?.connectedEntityName}. Ya puedes acceder a tus carteras actualizadas.`;
          secondaryId = reimportObject.connection;
        } catch {}
        break;
      case 'CONNECTION_IMPORT_ERROR':
        const { connections } = state.portfolio;
        const index = connections.findIndex((i: ConnectionType) => i.id === not.message);
        topic = `Tu conexión ${
          index !== -1 ? connections[index].name : ''
        } ha sufrido un error de importación.`;
        message = `Hemos abierto una incidencia y estamos revisándolo. Por favor, no elimines la conexión.`;
        break;
      case 'CONNECTION_UPDATED':
        topic = 'Conexión actualizada';
        message = connectionUpdateNot(state.portfolio.connections, not.message);
        break;
      case 'CONNECTION_ENABLED':
        topic = 'Cuenta habilitada';
        message = connectionEnabledNot(state.portfolio.connections, not.message);
        break;
      case 'UNLISTED_ACTIONS':
        topic = `Acciones no encontradas en ${not.message}`;
        message = `No hemos podido encontrar algunas acciones de tu cartera ${not.message}.`;
        secondaryId = not.message;
        break;
      case 'NEW_DIVIDEND':
        message = `El activo ${not.message.split('_')[0]} en tu cartera ${
          not.message.split('_')[1]
        } tiene un nuevo dividendo a pagar.`;
        secondaryId = not.message.split('_')[1];
        topic = '¡Nuevo dividendo confirmado!';
        break;
      case 'EARNED_DIVIDEND':
        const portfolioId = not.message.split('_')[1];
        const portfolio =
          state.portfolio.portfolios[portfolioId] ?? _portfolioWalletMock('not_valid');
        message = `Hoy recibirás el pago del dividendo de ${
          not.message.split('_')[0]
        } en tu cartera ${portfolio.name}.`;
        secondaryId = not.message.split('_')[1];
        topic = '¡Dividendo cobrado!';
        break;
      case 'SPLIT_DONE':
        message = `Hoy se ha hecho efectivo el split del ${
          not.message.split('_')[0]
        } en tu cartera ${not.message.split('_')[1]}.`;
        secondaryId = not.message.split('_')[1];
        topic = '¡Split realizado!';
        break;
      case 'NEW_SPLIT':
        message = not.message.split('_')[0];
        secondaryId = not.message.split('_')[1];
        topic = '¡Nuevo split confirmado!';
        break;
      case 'REPORT_GENERATED':
        topic = `Nuevo reporte generado`;
        message = `Tiene un reporte listo para descargar.`;
        break;
      case 'REPORT_FAILED':
        topic = `Error al generar reporte`;
        message = `Hemos tenido un problema al generar su reporte. Le informaremos cuando se solucione.`;
        break;
      default:
        message = not.message ?? '';
        topic = 'Información';
        break;
    }

    return { ...not, topic, message, secondaryId };
  });

export const transformActionNotifications = (
  notifications: Notification[],
  navigate: NavigateFunction,
  state: RootState
) => {
  let onPress;
  const newNotifications = [...notifications];
  return newNotifications.reverse().map((not) => {
    switch (not.code) {
      case 'PAYMENT_FAILED':
        onPress = () => navigate(PATH_DASHBOARD.user.account);
        break;
      case 'CONNECTION_SUCCESS':
        const { connections } = state.portfolio;
        const index = connections.findIndex((i: ConnectionType) => i.id === not.secondaryId);
        onPress = () =>
          index !== -1
            ? connections[index].displayed
              ? navigate(PATH_DASHBOARD.myConnections)
              : navigate(PATH_DASHBOARD.general.wallets.newConnection, {
                  state: { connection: connections[index] },
                })
            : {};
        break;
      case 'NEW_DIVIDEND':
      case 'EARNED_DIVIDEND':
      case 'SPLIT_DONE':
      case 'NEW_SPLIT':
        onPress = () => {};
        if (not.secondaryId && not.secondaryId !== '')
          onPress = () => navigateToWallet(not.secondaryId ?? '', navigate);
        break;
      case 'REPORT_GENERATED':
      case 'REPORT_FAILED':
        onPress = () =>
          navigate(PATH_DASHBOARD.general.reporting.all, {
            state: { tab: 'historic' },
            replace: true,
          });
        break;
      default:
        onPress = () => {};
        break;
    }

    return { ...not, onPress };
  });
};

const navigateToWallet = (walletName: string, navigate: NavigateFunction) =>
  navigate(`${PATH_DASHBOARD.general.wallets.root}/${replaceAll(walletName ?? '', ' ', '-')}`);

export const singleTransformerNot = (
  not: Notification,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  store: RootState
) => {
  log('NOTIFICATION', not);
  let { message } = not;
  let secondaryId = '';
  let topic = '';
  switch (not.code) {
    case 'MAPPED_SECURITIES':
      topic = 'Nuevos activos importados';
      message =
        'Hemos añadido activos que no pudimos encontrar anteriormente en tus carteras. Por favor comprueba tus carteras y reimporta para ver dichos activos en tu cartera.';
      break;
    case 'PAYMENT_FAILED':
      topic = 'Subscripción';
      message = 'Se ha producido un error al realizar el pago de tu subscripción.';
      break;
    case 'CONNECTION_SUCCESS':
      const object = JSON.parse(not.message);
      topic = `Conexión con ${object?.connectedEntityName} realizada correctamente`;
      message = `Se ha importado ${object?.newPortfolios} carteras de inversión y ${object?.newCashAccounts} cuentas corrientes.`;
      secondaryId = object.connection;
      const connection = store.portfolio.connections.find(
        (i: ConnectionType) => i.id === secondaryId
      );
      if (connection) dispatch(updateConnection({ ...connection, pending: false }));
      break;
    case 'CONNECTION_REIMPORT_SUCCESS':
      try {
        const reimportObject = JSON.parse(not.message);
        topic = `Conexión con ${reimportObject?.connectedEntityName} reimportada correctamente`;
        message = `Se ha reimportado correctamente tu conexión con ${reimportObject?.connectedEntityName}. Ya puedes acceder a tus carteras actualizadas.`;
        secondaryId = reimportObject.connection;
      } catch {}
      dispatch(getConnectionsAction());
      break;
    case 'CONNECTION_IMPORT_ERROR':
      const { connections } = store.portfolio;
      const index = connections.findIndex((i: ConnectionType) => i.id === not.message);
      topic = `Tu conexión ${
        index !== -1 ? connections[index].name : ''
      } ha sufrido un error de importación.`;
      message = `Hemos abierto una incidencia y estamos revisándolo. Por favor, no elimines la conexión.`;
      if (connections[index])
        dispatch(updateConnection({ ...connections[index], pending: false, errored: true }));
      break;
    case 'CONNECTION_UPDATED':
      topic = 'Conexión actualizada';
      message = connectionUpdateNot(store.portfolio.connections, not.message);
      break;
    case 'CONNECTION_ENABLED':
      topic = 'Cuenta habilitada';
      message = connectionEnabledNot(store.portfolio.connections, not.message);
      break;
    case 'UNLISTED_ACTIONS':
      topic = `Acciones no encontradas en ${not.message}`;
      message = `No hemos podido encontrar algunas acciones de tu cartera ${not.message}.`;
      secondaryId = not.message;
      const unListedPortfolio: any = Object.values(store.portfolio.portfolios).find(
        (i: any) => i.name === not.message
      );
      if (!!unListedPortfolio) dispatch(addPortfolio({ ...unListedPortfolio, incomplete: true }));
      break;
    case 'NEW_DIVIDEND':
      message = not.message.split('_')[0];
      secondaryId = not.message.split('_')[1];
      topic = '¡Nuevo dividendo confirmado!';
      break;
    case 'EARNED_DIVIDEND':
      const portfolioId = not.message.split('_')[1];
      const portfolio =
        store.portfolio.portfolios[portfolioId] ?? _portfolioWalletMock('not_valid');
      message = `Hoy recibirás el pago del dividendo de ${
        not.message.split('_')[0]
      } en tu cartera ${portfolio.name}.`;
      secondaryId = not.message.split('_')[1];
      topic = '¡Dividendo cobrado!';
      break;
    case 'SPLIT_DONE':
      message = not.message.split('_')[0];
      secondaryId = not.message.split('_')[1];
      topic = '¡Split realizado!';
      break;
    case 'NEW_SPLIT':
      message = not.message.split('_')[0];
      secondaryId = not.message.split('_')[1];
      topic = '¡Nuevo split confirmado!';
      break;
    case 'REPORT_GENERATED':
      topic = `Nuevo reporte generado`;
      message = `Tiene un reporte listo para descargar.`;
      break;
    case 'REPORT_FAILED':
      topic = `Error al generar reporte`;
      message = `Hemos tenido un problema al generar su reporte. Le informaremos cuando se solucione.`;
      break;
    default:
      message = not.message ?? '';
      topic = 'Información';
      break;
  }
  return { ...not, topic, message, secondaryId };
};

export const transformIconNotifications = (not: Notification) => {
  let result = {
    iconify: '',
    url: `${S3_URL}/${LOGOS_URL}/vumi_icon.png`,
    color: '',
  };

  switch (not.code) {
    case 'MAPPED_SECURITIES':
      result.iconify = 'tabler:chart-candle-filled';
      result.color = 'text.primary';
      break;
    case 'PAYMENT_FAILED':
      break;
    case 'CONNECTION_SUCCESS':
      result.iconify = 'octicon:verified-16';
      result.color = 'info.main';
      break;
    case 'CONNECTION_UPDATED':
      result.iconify = 'material-symbols:work-update-outline';
      result.color = ASSETS_COLORS.sea.main;
      break;
    case 'UNLISTED_ACTIONS':
      result.iconify = 'ph:warning-bold';
      result.color = 'warning.main';
      break;
    case 'CONNECTION_IMPORT_ERROR':
      result.iconify = 'icon-park-outline:error';
      result.color = 'error.main';
      break;
    case 'NEW_DIVIDEND':
    case 'EARNED_DIVIDEND':
      result.iconify = 'noto-v1:euro-banknote';
      result.color = '';
      break;
    case 'SPLIT_DONE':
    case 'NEW_SPLIT':
      result.iconify = 'humbleicons:arrow-main-split-side';
      result.color = 'info.main';
      break;
    case 'REPORT_GENERATED':
      result.iconify = 'bi:filetype-pdf';
      result.color = 'success.main';
      break;
    case 'REPORT_FAILED':
      result.iconify = 'fluent:document-error-16-regular';
      result.color = 'error.main';
      break;
    default:
      break;
  }
  return result;
};

const connectionUpdateNot = (connections: ConnectionType[], message: string) => {
  const index = connections.findIndex((i: ConnectionType) => i.id === message);
  return `Tu conexión ${
    index !== -1 ? connections[index].name : ''
  } se ha actualizado correctamente.`;
};

const connectionEnabledNot = (connections: ConnectionType[], message: string) => {
  const index = connections.findIndex((i: ConnectionType) => i.id === message);
  return `Tu conexión ${
    index !== -1 ? connections[index].name : ''
  } ya tiene las cuentas habilitadas`;
};
